import { RouterProvider } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { router } from 'router';

const App = observer(() => {
  useEffect(() => onResize(), []);

  const onResize = () => {
    window.requestAnimationFrame(onResize);

    const app = document.querySelector('.application');
    app.style.setProperty('--vh', `${window.innerHeight / 100}px`);
  };

  return (
    <div className="application">
      <RouterProvider router={router} />
    </div>
  );
});

export default App;
