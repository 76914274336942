export const links = {
  map: 'https://www.google.com/maps/place/ZILA+REAL+ESTATE/@25.0961756,55.1673784,17z/data=!3m1!4b1!4m6!3m5!1s0x3e5f6b0024a6d411:0x6a520485a991d725!8m2!3d25.0961756!4d55.1673784!16s/g/11vm07yvds?entry=ttu ',
  tel: '+971 56 757 2857',
  facebook: 'https://www.facebook.com/zilarealestate',
  linkedIn: 'https://www.linkedin.com/company/zilarealestate/',
  email: 'ask@zila.ae',
  trimTel() {
    return this.tel.replaceAll(' ', '');
  }
};
