import { observer } from 'mobx-react';

import background from 'assets/img/background.jpg';
import logo from 'assets/img/logo-extended.svg';

const Page = observer(() => {
  return (
    <div className="about-page">
      <div className="about-page__background">
        <img className="about-page__background-image" src={background} alt="background" />
        <div className="about-page__background-blackout"></div>
      </div>
      <main className="about-page__content">
        <div className="about-page__header">
          <img src={logo} alt="logo" />
          <div className="about-page__header-divider"></div>
          <h1>About Us</h1>
        </div>
        <div className="about-page__paragraph">
          <h2>Welcome to ZILA – Your Premier Real Estate Partner in Dubai</h2>
          <p>
            ZILA is a distinguished boutique real estate agency based in Dubai, dedicated to
            providing exceptional, personalized services in the ever-evolving real estate market.
            Established by a successful and experienced broker, our agency stands out for its
            commitment to excellence, advanced technology, and strong partnerships with Dubai’s
            leading developers.
          </p>
        </div>
        <div className="about-page__paragraph">
          <h2>Our Services</h2>
          <p>
            At ZILA, we offer a comprehensive range of real estate services tailored to meet the
            unique needs of our clients. Our core services include:
          </p>
          <ul>
            <li>
              <b>• Off-Plan Property Sales: </b>
              We provide expert guidance and exclusive access to Dubai’s most promising new
              developments, ensuring our clients secure the best deals at the earliest stages.
            </li>
            <li>
              <b>• Secondary Market Sales: </b>
              Our extensive network and market knowledge enable us to offer a wide selection of
              high-quality resale properties, catering to diverse preferences and budgets.
            </li>
            <li>
              <b>• Property Management: </b>
              We offer full-service property management solutions, including maintenance, tenant
              relations, and financial reporting, to ensure your investments are well-cared for and
              profitable.
            </li>
            <li>
              <b>• Flipping and Investment: </b>
              Our team specializes in identifying lucrative investment opportunities and executing
              successful flipping projects, maximizing returns for our clients.
            </li>
          </ul>
        </div>
        <div className="about-page__paragraph">
          <h2>Our Advantages</h2>
          <p>
            ZILA is renowned for several key advantages that set us apart in the competitive Dubai
            real estate market:
          </p>
          <ul>
            <li>
              • Personalized Service: We believe in building lasting relationships with our clients,
              offering personalized attention and bespoke solutions to meet their specific needs.
            </li>
            <li>
              • Innovative Technology: We leverage the latest technology to enhance our services,
              including virtual tours, advanced market analytics, and seamless online transactions.
            </li>
            <li>
              • Market Expertise: Our team possesses deep knowledge of the Dubai real estate market,
              allowing us to provide insightful advice and strategic guidance to our clients.
            </li>
            <li>
              • Strong Developer Partnerships: We maintain strong relationships with over 80 of
              Dubai’s top developers, giving our clients access to exclusive listings and special
              deals.
            </li>
          </ul>
        </div>
        <div className="about-page__paragraph">
          <h2>Our Story</h2>
          <p>
            ZILA was founded with a vision to redefine the real estate experience in Dubai. Our
            founder, a successful broker with a wealth of industry experience, recognized the need
            for a more personalized and technologically advanced approach to real estate. This
            vision has guided our growth and success, earning us a place in EMAAR’s TOP-100 real
            estate agencies in Dubai.
          </p>
          <p>
            Since our inception, we have been dedicated to exceeding our clients’ expectations,
            providing exceptional service and achieving outstanding results. Our commitment to
            excellence and innovation has made us a trusted partner for buyers, sellers, and
            investors alike.
          </p>
        </div>
        <div className="about-page__paragraph">
          <h2>Our Commitment</h2>
          <p>
            At ZILA, we are committed to delivering the highest level of service and satisfaction to
            our clients. Whether you are looking to buy, sell, or manage a property, our team is
            here to provide expert guidance and support every step of the way. We pride ourselves on
            our integrity, professionalism, and dedication to our clients’ success.
          </p>
        </div>
        <div className="about-page__rights">2024 © ZILA GREEN REAL ESTATE LLC</div>
      </main>
    </div>
  );
});

export default Page;
