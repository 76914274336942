import About from 'Pages/About/Index';

export default {
  path: '/about',
  children: [
    {
      index: true,
      element: <About />
    }
  ]
};
