import Contacts from 'Pages/Contacts/Index';

export default {
  path: '/contacts',
  children: [
    {
      index: true,
      element: <Contacts />
    }
  ]
};
