import { observer } from 'mobx-react';
import { links } from 'config';

import background from 'assets/img/background.jpg';
import logo from 'assets/img/logo-extended.svg';

const Page = observer(() => {
  return (
    <div className="contacts-page">
      <div className="contacts-page__background">
        <img className="contacts-page__background-image" src={background} alt="background" />
        <div className="contacts-page__background-blackout"></div>
      </div>
      <main className="contacts-page__content">
        <div className="contacts-page__header">
          <img src={logo} alt="logo" />
          <div className="contacts-page__header-divider"></div>
          <h1>Contact Us</h1>
        </div>
        <div className="contacts-page__title">
          <h2>Get in Touch with ZILA</h2>
          <p>
            We would love to hear from you! Whether you have questions about our services, need
            assistance with a property transaction, or simply want to learn more about what we
            offer, our team is here to help.
          </p>
        </div>
        <div className="contacts-page__address">
          <h2>Office Address</h2>
          <a href={links.map} rel="noreferrer" target="_blank">
            <span> EMAAR Business Park - Building 4, Dubai, United Arab Emirates</span>
          </a>
        </div>
        <div className="contacts-page__contacts">
          <div className="contacts-page__contacts-phone">
            <h2>Phone:</h2>
            <a href={`tel: ${links.trimTel()}`} rel="noreferrer" target="_blank">
              <span>{links.tel}</span>
            </a>
          </div>
          <div className="contacts-page__contacts-email">
            <h2>Email:</h2>
            <a href={`mailto: ${links.email}`} rel="noreferrer" target="_blank">
              <span>{links.email}</span>
            </a>
          </div>
        </div>
        <div className="contacts-page__social-networks">
          <div className="contacts-page__social-networks-facebook">
            <a href={links.facebook} rel="noreferrer" target="_blank">
              <span>Facebook</span>
            </a>
          </div>
          <div className="contacts-page__social-networks-linkedIn">
            <a href={links.linkedIn} rel="noreferrer" target="_blank">
              <span>LinkedIn</span>
            </a>
          </div>
        </div>
        <div className="contacts-page__rights">2024 © ZILA GREEN REAL ESTATE LLC</div>
      </main>
    </div>
  );
});

export default Page;
